$(function(){
    var $fancySelect = $('select.fancy-select');
    if ($fancySelect.length) {
        $fancySelect.select2({
            tags: true,
            width: '100%',
            placeholder: 'Zvoľte možnosť',
            allowClear: true
        });

        $fancySelect.on('select2:select', function (event) {
            var $element = $(event.params.data.element);

            $element.detach();
            $(this).append($element);
            $(this).trigger('change');
        });
    }

    var $flashModal = $('div.modal-flash');
    if ($flashModal.length) {
        $flashModal.modal('show');
    }

    var $switchableModal = $('div.modal-switchable');
    if ($switchableModal.length) {
        $switchableModal.on('show.bs.modal', function (event) {
            var button = $(event.relatedTarget);
            var content = button.data('content');

            $('div.modal-body', $(this)).text(content);
        });
    }

    var $statefulButton = $('button.btn-stateful');
    if ($statefulButton.length) {
        $statefulButton.on('click', function (event) {
            event.preventDefault();

            $(this).prop('disabled', true);

            $(this.form).submit();
        });
    }

    var $dataTable = $('table.table-datatable');
    if ($dataTable.length) {
        $dataTable.DataTable({
            paging: false,
            info: false
        });
    }
});
